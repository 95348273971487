'use client';

import clsx from 'clsx';
import { Link } from './link';
import { useState } from 'react';

export const RecommendationButton = ({
  text,
  url,
  textColor = '',
  className = '',
  hoverTextColor = '',
  hoverBgColor = ''
}: {
  text: string;
  url: string;
  textColor?: string;
  className?: string;
  hoverTextColor?: string;
  hoverBgColor?: string;
}) => {
  const [isHover, setIsHover] = useState(false);
  const inlineStyles = hoverTextColor
    ? {
        color: isHover ? hoverTextColor : textColor,
        borderColor: isHover ? hoverBgColor : textColor,
        backgroundColor: isHover ? hoverBgColor : 'transparent',
        transition: 'all 300ms 300ms',
      }
    : {};

  return (
    <h3
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={inlineStyles}
      className={clsx(
        className,
        `group transition-all border-b duration-1000 w-max font-kronaOne text-base uppercase py-1 hover:bg-black`,
        textColor === '#ffffff'
          ? 'box-content text-white border-none flex flex-col justify-between pb-0 h-[1.6875rem]'
          : 'border-black text-black hover:text-white h-[1.875rem]'
      )}
    >
      <Link href={url}>{text}</Link>
      {textColor === '#ffffff' && (
        <div>
          <div className="transition-all duration-1000  bg-white w-full h-[.0625rem] opacity-0 group-hover:opacity-100" />
          <div className="transition-all duration-1000 bg-white w-full h-[.0625rem]" />
        </div>
      )}
    </h3>
  );
};
