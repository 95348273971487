'use client';

import { SolidBannerItem } from '@theme/types';
import React from 'react';
import SolidBannerVideo from '@theme/components/solid-banner-video';
import { twMerge } from 'tailwind-merge';

interface SolidBannerWidgetProps {
  data: SolidBannerItem;
  classes?: string;
}

const SolidBannerWidget = (props: SolidBannerWidgetProps) => {
  const { data, classes = '' } = props;
  const isShow = data?.kwargs?.value?.is_show?.value === 'yes';

  if (!isShow) return null;

  return (
    <div className={twMerge('w-full', classes)}>
      {data?.kwargs?.value?.mobile_video?.url &&
        data?.kwargs?.value?.desktop_video?.url && (
          <>
            {/* MOBILE SIZE DEVICE */}
            <SolidBannerVideo
              poster={data?.kwargs?.value?.desktop_image?.url ?? ''}
              source={data?.kwargs?.value?.mobile_video?.url}
              alt={data?.value?.alt ?? ''}
              conditionForLoop={data?.value?.mobile_auto === 'yes'}
              className={'lg:hidden'}
            />
            {/* DESKTOP SIZE DEVICE */}
            <SolidBannerVideo
              poster={data?.kwargs?.value?.desktop_image?.url ?? ''}
              source={data?.kwargs?.value?.desktop_video?.url}
              conditionForLoop={data?.value?.desktop_auto === 'yes'}
              className={'hidden lg:inline-block'}
              alt={data?.value?.alt ?? ''}
            />
          </>
        )}
    </div>
  );
};

export default SolidBannerWidget;
