import { Image } from '@akinon/next/components/image';
import { useState } from 'react';

const SolidBannerVideo = ({
  conditionForLoop,
  source,
  className,
  alt,
  poster
}: {
  conditionForLoop: boolean;
  source: string;
  className: string;
  alt: string;
  poster: string;
}) => {
  const [isClicked, setIsClicked] = useState(conditionForLoop);
  const lastFourChar = source.slice(-4);

  return (
    <>
      {lastFourChar.includes('gif') || lastFourChar.includes('mp4') ? (
        <div className={className}>
          {lastFourChar.includes('gif') ? (
            <Image
              className="w-full h-full"
              imageClassName="w-full h-full inline-block"
              src={source}
              alt={alt}
              width={375}
              height={644}
            />
          ) : (
            <video
              poster={poster}
              onClick={() => setIsClicked(true)}
              autoPlay={conditionForLoop || isClicked}
              muted
              controls={isClicked}
              loop={conditionForLoop}
              className={'w-full cursor-pointer inline-block'}
            >
              <source src={source} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SolidBannerVideo;
