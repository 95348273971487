export default function ArrowRightIcon( props) {
    const { className, ...rest } = props;

    return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path opacity="0.2" d="M27 16.5234L18 25.5234V7.52344L27 16.5234Z" />
      <path d="M27.7075 15.8167L18.7075 6.81673C18.5676 6.67671 18.3894 6.58135 18.1953 6.5427C18.0012 6.50405 17.8 6.52385 17.6172 6.5996C17.4344 6.67535 17.2782 6.80365 17.1683 6.96824C17.0584 7.13284 16.9998 7.32633 17 7.52422V15.5242H5C4.73478 15.5242 4.48043 15.6296 4.29289 15.8171C4.10536 16.0047 4 16.259 4 16.5242C4 16.7894 4.10536 17.0438 4.29289 17.2313C4.48043 17.4189 4.73478 17.5242 5 17.5242H17V25.5242C16.9998 25.7221 17.0584 25.9156 17.1683 26.0802C17.2782 26.2448 17.4344 26.3731 17.6172 26.4488C17.8 26.5246 18.0012 26.5444 18.1953 26.5058C18.3894 26.4671 18.5676 26.3717 18.7075 26.2317L27.7075 17.2317C27.8005 17.1389 27.8742 17.0286 27.9246 16.9072C27.9749 16.7858 28.0008 16.6556 28.0008 16.5242C28.0008 16.3928 27.9749 16.2627 27.9246 16.1413C27.8742 16.0199 27.8005 15.9096 27.7075 15.8167ZM19 23.1105V9.93797L25.5863 16.5242L19 23.1105Z" />
    </svg>
  );
}
