'use client';

import React from 'react';
import useWindowResize from '@theme/hooks/use-window-resize';
import { Image } from '@akinon/next/components/image';
import styles from './styles.module.css';
import { twMerge } from 'tailwind-merge';

export default function Campaigns({ data }) {
  const CampaignsData = data?.attributes;
  const windowSize = useWindowResize();

  return (
    <div className="campaigns-container px-3 lg:max-w-[74.625rem] mx-auto">
      <div>
        <h2 className="lg:my-4 mt-4 text-2xl lg:text-[2rem] lg:mb-6 lg:mt-12 font-kronaOne">
          Kampanyalar
        </h2>
        {CampaignsData?.campaigns.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className="campaign flex flex-col lg:flex-row gap-9 lg:py-6 pb-4 mt-4 border-b border-b-steam"
          >
            {windowSize.width < 768 ? (
              <>
                <Image
                  src={item?.kwargs?.value?.mobile_image?.url}
                  alt="Visa"
                  width={351}
                  height={126}
                />
              </>
            ) : (
              <>
                <Image
                  src={item?.kwargs?.value?.mobile_image?.url}
                  alt="Visa"
                  width={702}
                  height={252}
                />
              </>
            )}
            <div
              className={twMerge(
                'campaign-details text-sm font-sans lg:w-[28.5rem]'
              )}
            >
              <div
                className={twMerge(
                  styles.details,
                  'campaign-details text-sm font-sans lg:max-w-[28.5rem] pl-3 lg:pl-0'
                )}
                dangerouslySetInnerHTML={{
                  __html: item?.value?.campaign_info
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
