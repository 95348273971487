export default function ArrowLeftIcon(props) {
  const { className, ...rest } = props;

  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path opacity="0.2" d="M14 7.52344V25.5234L5 16.5234L14 7.52344Z" />
      <path d="M27 15.5242H15V7.52422C15.0001 7.32633 14.9416 7.13284 14.8317 6.96824C14.7218 6.80365 14.5656 6.67535 14.3828 6.5996C14.2 6.52385 13.9988 6.50405 13.8047 6.5427C13.6106 6.58135 13.4323 6.67671 13.2925 6.81673L4.29249 15.8167C4.19952 15.9096 4.12576 16.0199 4.07543 16.1413C4.02511 16.2627 3.99921 16.3928 3.99921 16.5242C3.99921 16.6556 4.02511 16.7858 4.07543 16.9072C4.12576 17.0286 4.19952 17.1389 4.29249 17.2317L13.2925 26.2317C13.4323 26.3717 13.6106 26.4671 13.8047 26.5058C13.9988 26.5444 14.2 26.5246 14.3828 26.4488C14.5656 26.3731 14.7218 26.2448 14.8317 26.0802C14.9416 25.9156 15.0001 25.7221 15 25.5242V17.5242H27C27.2652 17.5242 27.5196 17.4189 27.7071 17.2313C27.8946 17.0438 28 16.7894 28 16.5242C28 16.259 27.8946 16.0047 27.7071 15.8171C27.5196 15.6296 27.2652 15.5242 27 15.5242ZM13 23.1105L6.41374 16.5242L13 9.93797V23.1105Z" />
    </svg>
  );
}
