'use client';

import React, { useEffect } from 'react';
import { ProductItem } from '@theme/views/product-item';
import { RecommendationButton } from '@theme/components/recommendationButton';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { Image } from '@akinon/next/components/image';
import { twMerge } from 'tailwind-merge';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';

// register Swiper custom elements
register();

export default function RecommendationContent({
  data,
  sliderId,
  classes = ''
}) {
  const products = data?.products;

  useEffect(() => {
    const swiperElement = document.getElementById(sliderId);

    if (!swiperElement) return;

    Object.assign(swiperElement, {
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 16
        },
        425: {
          slidesPerView: 1.6,
          spaceBetween: 16
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        640: {
          slidesPerView: 2.5,
          spaceBetween: 16
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 36
        }
      }
    });

    (swiperElement as any).initialize();
  }, [data, sliderId]);

  const renderSlides = () => {
    return products?.map((product, index) => (
      <swiper-slide key={index} style={{ height: 'auto' }}>
        <ProductItem
          product={product}
          index={index}
          width={512}
          height={512}
          textColor={data?.attributes?.product_item_text_color?.value}
          favouriteIconColor={data?.attributes?.favourite_icon_color?.value}
          favouriteIconColorActive={
            data?.attributes?.favourite_icon_color_active?.value
          }
          favouriteIconColorHover={
            data?.attributes?.favourite_icon_color_hover?.value
          }
          favouriteIconColorHoverFill={
            data?.attributes?.favourite_icon_color_hover_fill_color?.value
          }
          basketOfferTextColor={
            data?.attributes?.basket_offer_text_color?.value
          }
          basketOfferBackgroundColor={
            data?.attributes?.basket_offer_background_color?.value
          }
          discountBadgeTextColor={
            data?.attributes?.discount_badge_text_color?.value
          }
          discountBadgeBackgroundColor={
            data?.attributes?.discount_badge_background_color?.value
          }
          removeLazyFromFirstImage={true}
          className="h-full"
        />
      </swiper-slide>
    ));
  };

  return (
    <div
      className={twMerge(
        `flex items-center justify-center ps-3 py-8 lg:py-[4.5rem] lg:px-8 w-full [&_#${sliderId}]:w-full [&_#${sliderId}]:h-full`,
        classes
      )}
      style={{ backgroundColor: data?.attributes?.background_color?.value }}
    >
      <div className="w-full max-w-screen-2.5xl relative">
        {data?.attributes?.title?.value && (
          <header className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-y-4 mb-[1.125rem] font-kronaOne">
            <h2 className="flex gap-x-3 items-center">
              <span
                className="text-xl lg:text-2xl 4xl:text-[2rem]"
                style={{ color: data?.attributes?.title_color?.value }}
              >
                {data?.attributes?.title?.value}
              </span>
              {data?.attributes?.icon?.kwargs?.url && (
                <Image
                  src={data?.attributes?.icon?.kwargs?.url}
                  alt="icon"
                  width={30}
                  height={30}
                />
              )}
            </h2>
            {data?.attributes?.subtitle?.value && (
              <RecommendationButton
                text={data?.attributes?.subtitle?.value}
                url={data?.attributes?.url?.value}
                textColor={data?.attributes?.subtitle_color?.value}
                className="text-sm lg:text-base"
              />
            )}
          </header>
        )}
        <swiper-container
          id={sliderId}
          init={false}
          navigation="true"
          navigation-next-el={'#custom-nav-button--next-' + sliderId}
          navigation-prev-el={'#custom-nav-button--prev-' + sliderId}
        >
          {products && renderSlides()}
        </swiper-container>
        <button
          id={'custom-nav-button--prev-' + sliderId}
          className="absolute z-[8] top-1/2 4xl:-left-[4.1875rem] max-4xl:hidden disabled:opacity-30"
        >
          <ArrowLeftIcon
            style={{ fill: `${data?.attributes?.title_color?.value}` }}
            className={twMerge('w-8 h-8')}
          />
        </button>
        <button
          id={'custom-nav-button--next-' + sliderId}
          className="absolute z-[8] top-1/2 4xl:-right-[4.1875rem] max-4xl:hidden disabled:opacity-30"
        >
          <ArrowRightIcon
            style={{ fill: `${data?.attributes?.title_color?.value}` }}
            className={twMerge('w-8 h-8')}
          />
        </button>
      </div>
    </div>
  );
}
