'use client';

import React from 'react';

import { Image } from '@akinon/next/components/image';
import { twMerge } from 'tailwind-merge';
import { RecommendationButton } from '@theme/components/recommendationButton';
import clsx from 'clsx';
import { IImageAndDescription } from '@theme/types';
import { WidgetResultType } from '@akinon/next/types';
import { Link } from '@theme/components';

export default function ImageAndDescriptionContent({
  data,
  isLandingPage = false,
  classes = '',
  iconWrapperClass = '',
  iconClass = '',
  lastWrapperClasses = ''
}: {
  data: WidgetResultType<IImageAndDescription>;
  isLandingPage?: boolean;
  classes: string;
  iconWrapperClass?: string;
  iconClass?: string;
  lastWrapperClasses?: string;
}) {
  const bigImage = data?.attributes?.big_image?.kwargs?.url;
  const bigImageAlt = data?.attributes?.big_image_alt_text?.value;
  const bigImageLink = data?.attributes?.big_image_link?.value;

  const smallImage = data?.attributes?.small_image?.kwargs?.url;
  const smallImageAlt = data?.attributes?.small_image_alt_text?.value;
  const smallImageLink = data?.attributes?.small_image_link?.value;

  const icon = data?.attributes?.icon?.kwargs?.url;

  const title = data?.attributes?.text_content?.value?.title;
  const description = data?.attributes?.text_content?.value?.description;
  const link = data?.attributes?.text_content?.value?.link;
  const linkText = data?.attributes?.text_content?.value?.link_text;

  return (
    <div
      className={twMerge(
        `max-w-[74.625rem] mx-auto lg:flex lg:justify-between lg:gap-x-8`,
        classes
      )}
    >
      <div className="relative lg:flex lg:justify-between">
        <Link
          className="w-full lg:min-w-[36.1875rem] lg:h-[53.25rem] lg:self-end"
          href={bigImageLink || link || '/'}
        >
          <Image
            className="w-full lg:min-w-[36.1875rem] lg:h-[53.25rem] lg:self-end"
            imageClassName="w-full lg:h-[53.25rem]"
            src={bigImage}
            alt={bigImageAlt}
            height={550}
            width={374}
          />
        </Link>

        {isLandingPage && (
          <Image
            className={twMerge(
              'hidden absolute z-[1] lg:inline-block lg:top-[225px] lg:-right-[165px]',
              iconWrapperClass
            )}
            imageClassName={twMerge(
              'lg:w-[20.4375rem] lg:h-[11.1875rem]',
              iconClass
            )}
            src={icon}
            alt=""
            height={179}
            width={327}
          />
        )}
      </div>

      <div
        className={twMerge(
          'flex flex-col gap-y-6 lg:gap-y-[5.75rem]',
          lastWrapperClasses
        )}
      >
        <div>
          <div className="relative w-max ms-auto">
            <Link
              className="hidden lg:inline-block lg:w-[20.8125rem] lg:h-[30.625rem]"
              href={smallImageLink || link || '/'}
            >
              <Image
                className="hidden lg:inline-block lg:w-[20.8125rem] lg:h-[30.625rem]"
                src={smallImage}
                alt={smallImageAlt}
                height={490}
                width={333}
              />
            </Link>
            {!isLandingPage && (
              <Image
                className="hidden absolute lg:inline-block lg:bottom-10 lg:-left-[11.5rem]"
                imageClassName="lg:w-[20.4375rem] lg:h-[11.1875rem]"
                src={icon}
                alt=""
                height={179}
                width={327}
              />
            )}
          </div>
        </div>
        <div
          className={clsx(
            'px-6 lg:px-0 flex flex-col gap-y-8 lg:w-max lg:max-w-[30.75rem] lg:pb-10',
            { 'lg:!max-w-[20.8125rem] xl:-ml-[42%] !gap-y-4': isLandingPage }
          )}
        >
          <h3
            className={clsx(
              'text-2xl lg:text-[3.0625rem] lg:leading-[3.8281rem] font-kronaOne',
              {
                '!text-[2rem] leading-10 font-medium !font-kalnia':
                  isLandingPage
              }
            )}
          >
            {title}
          </h3>
          <p
            className={clsx('text-base lg:pe-1 2xl:pe-0', {
              'font-medium': isLandingPage
            })}
          >
            {description}
          </p>
          {link && (
            <RecommendationButton
              text={linkText}
              url={link}
              textColor="#000000"
            />
          )}
        </div>
      </div>
    </div>
  );
}
